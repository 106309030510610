class ArrayService {
  // eslint-disable-next-line no-unused-vars
  public filterBoth<T>(items: T[], fn: (item: T) => boolean): [T[], T[]] {
    const matchings: T[] = [];
    const leftovers: T[] = [];

    items.forEach((item) =>
      fn(item) ? matchings.push(item) : leftovers.push(item)
    );

    return [matchings, leftovers];
  }
}

export default new ArrayService();
